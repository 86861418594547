import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Link from '../components/link'

const BagsPage = ({ data: { allBagsYaml: { nodes } } }) => (
  <Layout>
    <SEO title="Plastic Bags" />

    <div className="bags">
      {nodes.map((node, index) => (
        <Link
          className="bags-bag"
          to={ `/bags/${node.slug}` }
          key={ index }
        >
          {node.image ? (
            <img
              className="bags-bag--image"
              src={ `/images/bags/${node.image}.jpg`}
              srcSet={ `/images/bags/${node.image}.jpg 1x, /images/bags/${node.image}@2x.jpg 2x` }
              alt={ node.name }
            />
          ) : (
            <div className="bags-bag--noimage">
              Photo coming soon.
            </div>
          )}

          <div className="bags-bag--name">
            { node.name }
          </div>
        </Link>
      ))}
    </div>
  </Layout>
)

export const query = graphql`
  query BagsPageQuery {
    allBagsYaml {
      nodes {
        name
        slug
        image
      }
    }
  }
`

export default BagsPage
